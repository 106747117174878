<template>
  <v-card flat tile>
    <v-overlay :value="overlay" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-toolbar flat color="white">
      <v-toolbar-title>
        <v-icon class="mb-1">mdi-format-list-bulleted</v-icon>
        MIS RESERVAS
      </v-toolbar-title>
    </v-toolbar>
    <div>
      <v-data-table
        :headers="headerReservas"
        :items="reservas"
        item-key="NUM_GUIA_FINCA"
        class="elevation-1"
      >
        <template v-slot:item.opciones="{ item }">
          <v-btn color="success" icon small text @click="reservar(item)">
            <v-icon color="warning">mdi-pencil</v-icon>
          </v-btn>

          <v-btn color="success" icon small text @click="cancelar(item)">
            <v-icon color="error">mdi-cancel</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.params_coord="{ item }">
          <span v-for="i in item.params_coord">
            <v-chip
              v-if="i.TIPO_PARAM == 'VARIEDAD'"
              small
              class="ma-1"
              :color="colorVariedad(i.VALOR_PARAM)"
            >
              {{ i.VALOR_PARAM }}
            </v-chip>
          </span>
        </template>

        <template v-slot:item.FECHA="{ item }">
          {{ formatoFecha(item.FECHA) }}
        </template>
      </v-data-table>

      <v-dialog v-model="dialogReserva" persistent max-width="660px">
        <v-card>
          <v-card-title>
            <span class="headline">Reserva</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="formReserva">
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12">
                    <v-autocomplete
                      :rules="_fechaDae"
                      :disabled="soloVer || esGuiaEncargos === false"
                      v-model="reserva.FUE_ID"
                      small
                      item-text="DAE_DEST"
                      item-value="FUE_ID"
                      :items="daes"
                      clearable
                      label="Seleccione una DAE"
                    >
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.FUE"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="data.item.DESTINO"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="px-2 py-5" cols="12">
                    <v-label class="px-12 py-4">Variedades</v-label>
                    <span v-for="i in reserva.VARIEDADES">
                      <v-chip
                        small
                        class="ma-1"
                        :color="colorVariedad(i.VALOR_PARAM)"
                      >
                        {{ i.VALOR_PARAM }}
                      </v-chip>
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pt-0 pb-0" cols="1.5">
                    <v-text-field v-model="reserva.fulles" label="Fulles">
                    </v-text-field>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="1.5">
                    <v-text-field v-model="reserva.mitades" label="Mitades">
                    </v-text-field>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="1.5">
                    <v-text-field v-model="reserva.tercios" label="Tercios">
                    </v-text-field>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="1.5">
                    <v-text-field v-model="reserva.cuartos" label="Cuartos">
                    </v-text-field>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="1.5">
                    <v-text-field v-model="reserva.sextos" label="Sextos">
                    </v-text-field>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="1.5">
                    <v-text-field v-model="reserva.octavos" label="Octavos">
                    </v-text-field>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="2">
                    <v-text-field
                      v-model="reserva.dieciseisavos"
                      label="Dieciseisavos"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pt-0 pb-0" cols="12">
                    <v-textarea
                      rows="2"
                      v-model="reserva.OBSERVACIONES"
                      label="Observaciones"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-col class="text-center">
              <v-btn color="info" small @click="guardarReserva()">
                <v-icon>mdi-content-save</v-icon> Guardar
              </v-btn>

              <v-btn small @click="dialogReserva = false">
                <v-icon>mdi-cancel</v-icon> Cancelar
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "ReservasCoordinadasComponent",

  data: () => ({
    headerReservas: [
      { text: "Opciones", align: "start", value: "opciones" },
      { text: "AWB", align: "start", value: "AWB" },
      { text: "Destino", align: "start", value: "DEST_CIUDAD" },
      { text: "Fecha", align: "start", value: "FECHA" },
      { text: "Cliente", align: "start", value: "CLIENTE" },
      { text: "Opciones", value: "params_coord" },
      { text: "Cajas", align: "start", value: "BOXES_COORD" },
      { text: "Estado", align: "start", value: "GUIA_ESTADO" },
      { text: "Guia hija", align: "start", value: "NUM_GUIA_FINCA" },
    ],
    reservas: [],
    destinos: [],
    daes: [],
    reserva: {
      VARIEDADES: [],
      FUE_ID: "",
      CABECERA_ID: "",
      FINCA_ID: "",
      HCOORD_ID: "",
      CNEE_ID: "",
      RATE: "",
      AWB: "",
      FECHA: "",
      COMER_ID: "",
      SCLIENTE_ID: "",
      full: "0",
      mitades: "0",
      tercios: "0",
      cuartos: "0",
      sextos: "0",
      octavos: "0",
      dieciseisavos: "0",
      OBSERVACIONES: "",
    },
    destinoId: "",
    cabeceraId: "",
    overlay: false,
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 10,
    dialogReserva: false,
    editarRes: false,
    soloVer: false,
    variedades: [
      { var: "ROSAS", color: "#ff3471" },
      { var: "FLOR DE VERANO", color: "#2267FF" },
    ],
    requiredRule: [(v) => !!v || "El campo es requerido"],
  }),
  watch: {},
  computed: {
    ...mapState("master", ["user", "loadingTable"]),

    ...mapState("access", ["loggedIn"]),

    esGuiaEncargos() {
      let res = false;
      if (
        !(
          this.reserva.AWB.includes("EZF") === true ||
          this.reserva.AWB.includes("FWC") === true ||
          this.reserva.AWB.includes("MAR") === true
        )
      )
        res = true;

      return res;
    },
    _fechaDae() {
      let rf = this.reserva.FECHA;

      if (this.reserva.FUE_ID == "" || this.reserva.FUE_ID == null) {
        if (
          !(
            this.reserva.AWB.includes("EZF") === true ||
            this.reserva.AWB.includes("FWC") === true ||
            this.reserva.AWB.includes("MAR") === true
          )
        )
          return [false || "Seleccione una DAE "];
      }
      for (let i = 0; i < this.daes.length; i++) {
        if (this.daes[i].FUE_ID == this.reserva.FUE_ID) {
          if (
            !moment(rf).isBetween(
              moment(this.daes[i].FECHA_INICIO),
              moment(this.daes[i].FECHA_CADUCA),
              undefined,
              "[]"
            )
          ) {
            return [
              false ||
                "La DAE seleccionada no se puede utilizar para esta fecha de vuelo",
            ];
          }
        }
      }
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingBtn",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    colorVariedad(x) {
      for (let i = 0; i <= this.variedades.length; i++) {
        if (this.variedades[i].var == x) {
          return this.variedades[i].color;
        }
      }
    },

    misReservas() {
      this.setLoadingTable(true);

      this.dataTable = [];

      this.setUrl("api/mis-reservas");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
        },
      })
        .then((res) => {
          this.reservas = res.data.reservas;
          this.daes = res.data.fues;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    reservar(item) {
      this.setUrl("api/cargar-reserva");
      this.requestApi({
        method: "GET",
        data: {
          detalle_id: item.DETALLE_ID,
        },
      })
        .then((res) => {
          console.log(res.data.reserva);
          this.reserva = {
            DETALLE_ID: res.data.reserva.DETALLE_ID,
            VARIEDADES: item.params_coord,
            FUE_ID: res.data.reserva.FUE_ID,
            CABECERA_ID: res.data.reserva.CABECERA_ID,
            FINCA_ID: res.data.reserva.FINCA_ID,
            HCOORD_ID: item.HCOORD_ID,
            CNEE_ID: res.data.reserva.CLIENTE_ID,
            RATE: res.data.reserva.RECARGO_COMBUS,
            AWB: res.data.reserva.AWB,
            FECHA: res.data.reserva.FECHA,
            COMER_ID: res.data.reserva.COMER_ID,
            SCLIENTE_ID: res.data.reserva.SCLIENTE_ID,
            fulles: res.data.reserva.fulles,
            mitades: res.data.reserva.mitades,
            tercios: res.data.reserva.tercios,
            cuartos: res.data.reserva.cuartos,
            sextos: res.data.reserva.sextos,
            octavos: res.data.reserva.octavos,
            dieciseisavos: res.data.reserva.dieciseisavos,
            OBSERVACIONES: res.data.reserva.OBSERVACIONES,
          };
        })
        .then(() => {
          this.setLoadingTable(false);
        });

      this.dialogReserva = true;
    },

    guardarReserva() {
      if (!this.$refs.formReserva.validate()) {
        return;
      }

      this.dialogReserva = false;

      this.setUrl("api/modificar-reserva");
      this.requestApi({
        method: "POST",
        data: this.reserva,
      })
        .then((res) => {
          this.guiasCoord = res.data.guias;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cancelar(item) {
      Vue.swal({
        html: "Está seguro de cancelar esta reserva  ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/cancelar-reserva");
          this.requestApi({
            method: "POST",
            data: {
              DETALLE_ID: item.DETALLE_ID,
            },
          }).then((res) => {
            this.alertNotification({ param: { html: res.data.msg } });
            this.misReservas();
          });
        }
      });
    },

    formatoFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("ddd D-MMM-Y");
    },
  },
  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("RESERVA DE CARGA");
    this.misReservas();
  },
};
</script>
